

.login-wrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: '100%';
 /*  border: 4px dotted red;  */
}

.login-form-button2 {
  background-color: #0C2FFA !important;
  border-radius: 32px !important;
  width: 10rem !important;
  height: 2.3rem !important;
}




.login-image-wrapper {
  width: 50vmin;
  background: url('../../assets/images/loginback2.png');
  background-size: cover;
}

@media (max-width: 800px) {
  .login-image-wrapper {
    width: 15vmin;
  }


}


.login-header {
  font-size: 54px;
/*   font-size: 4rem; */
  margin-top: 1vw;
  text-align: left;
  margin-bottom: -10px;
}




.login-namediv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login-appname {
  font-size: 20px;
}


.login-language {
  margin-left: 0.5em;
  width: 2em;
  border: 1px solid rgb(3, 3, 3);
  border-radius: 10px;
  padding-left: 1em;
}

.login-form-wrapper {
  /*  border: 4px dotted blue; */
   padding: 15px;
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -5em;
}

.login-form-content {
/*   border: 4px dotted blue;  */
}

.form-languageselector {
  /* border: 4px dotted blue;  */
  display: flex;
  flex: auto;
  align-items: center;
  margin-bottom: 2vmin;
}

.errorText {
  color: red;
  font-size: calc(1px + 2vmin);
}

.login-form {
/*   border: 4px dotted red;  */
}



.login-form-forgot {
  float: right;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

