.selectBtn {
    padding: 5px;
    background: rgba(123, 151, 206, 0.06);
    /* Accent color */
    
    border: 1px solid #2B4BF2;
    border-radius: 8px;
}

.header-language {
    margin-left: 1vw;
}

.selectOption {
    
}