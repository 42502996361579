.factorySwDiv {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
  }

.lngDropdown {
    margin-right: 5px;
    margin-left: 5px;
}

.menuValue {
    /*  text-transform: uppercase; */
    margin: 0;
    display: flex;
    justify-content: center;
}

.displayValue {
    margin: 0;
    cursor: pointer;
}

.displayValue:hover {
    color: #1890ff;
    transition: 0.3s;
}

.factorySelectorItem {
    background-color: #f5f5f5;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 10px;
/*     border: 1px solid #333; */
    color: #A0A0A0;
    margin: 0;
    font-size: 11px;
}


