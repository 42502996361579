.loaderContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader {
  /* background-color: red; */
  align-self:center
}